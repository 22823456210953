import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "lists-bulleted-and-numbered"
    }}>{`Lists (Bulleted and Numbered)`}</h1>
    <p>{`Only use lists if you have two or more items. You can use two types of lists: bulleted (itemized) or
numbered. Use bulleted lists when the order of items in the list doesn't matter, and numbered lists
when the items in the list need to follow a specific sequence.`}</p>
    <p>{`Here are universal instructions for lists:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Use no more than two levels of indentation.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Always introduce a list with a lead-in sentence. Use a colon at the end of the lead-in sentence.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Try to make your list items parallel. For example, if one list item starts with a verb, all the
others should follow the same format.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Use sentence-style capitalization for each list item: capitalize only the first letter of the
item.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`End bullet points with a period only if the list item stands alone as a full sentence. If a list
item is a fragment, don't include a period at the end. Exception: if a list item fragment is
followed by a full sentence, use a period at the end of the fragment and the end of the sentence
that follows.`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      